import React from 'react';
import { Autocomplete, Box, Paper, styled, Container, CssBaseline, Card, Grid, Divider, Typography, useMediaQuery, FormControl, InputLabel, Select, MenuItem, OutlinedInput, FormControlLabel, FormLabel, RadioGroup, Radio, TextField, Button, Checkbox, ListItemText, InputAdornment } from '@mui/material';
import banner22 from '../images/Bannermob13.png';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from "date-fns";
import Footer from '../components/Footer';
import { LocationOn } from '@mui/icons-material';
import kelly from '../images/logoS.2.png'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CustomBanner = styled(Paper)(({ theme }) => ({
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
        rgb(197 192 192 / 7%), 
        rgb(0 0 0 / 28%)
      ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 34,
    paddingTop: 60
}))

const CustomTextField = styled(TextField)({
    backgroundColor: '#fff',
    '& label.Mui-focused': {
        color: 'grey',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'grey',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'grey',
        },
    },

})

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#053048',
    ...theme.typography.body2,
    padding: 0,
    textAlign: 'center',
    fontSize: 15,
    color: '#F9F7F3',
    // fontFamily: 'initial',
}));

const countries = [
    { code: 'US', name: 'United States', dialCode: '+1' },
    { code: 'IN', name: 'India', dialCode: '+91' },
    // { code: 'GB', name: 'United Kingdom', dialCode: '+44' },
    // { code: 'CA', name: 'Canada', dialCode: '+1' },
    // Add more countries as needed
];




export const ClientInfo = () => {
    const isMatch = useMediaQuery('(max-width:600px )');
    const [formData, setFormData] = React.useState({ countryCode: "+1", });
    const [clientType, setClientType] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [country, setCountry] = React.useState(countries[0].dialCode);
    const [address, setAddress] = React.useState({
        currentAddress: "",
        listingAddress: "",
        investmentLocation: "",
    });
    const [addressOptions, setAddressOptions] = React.useState([]);
    const [clientInfo, setClientInfo] = React.useState("");

    const fields = [
        { title: 'Investment Goals', label: "Type of Investment", name: "entry.1982387935", isMasked: true, options: investmentTypes },
        { label: "Expected ROI", name: "entry.929542602", isMasked: false, },
        { label: "Preferred Holding Period", name: "entry.1084847060", isMasked: true, options: holdingPeriods },
        { label: "Interest in Off-Market Deals", name: "entry.1296138427", lender: true, },
        { title: 'Financial Information', label: "Budget Range for Investment", name: "entry.1155132064", isMasked: false },
        { label: "Proof of Funds Available?", name: "entry.784325682", lender: true, },
        { label: "Preferred Financing Method", name: "entry.370453960", isMasked: true, options: financingMethods },
        { label: "Open to Partnering with Other Investors?", name: "entry.1282991940", lender: true, },
        { title: 'Preferred Investment Locations', label: "Cities/Neighborhoods of Interest", name: "entry.1868758765", isMasked: false, isAutoComplete: true, },
        { label: "Preferred Property Types", name: "entry.595075475", isMasked: true, options: propertyTypes },
        { title: 'Risk Tolerance & Strategy', label: "Preferred Level of Involvement", name: "entry.2099810446", isMasked: true, options: levelofInvolvement },
        { label: "Open to Fixer-Uppers?", name: "entry.1643033356", lender: true, },
        { label: "Preferred Rental Strategy", name: "entry.198577793", isMasked: true, options: rentalStrategies },
        { label: "Need Property Management Assistance?", name: "entry.1323833776", lender: true, },
        { title: 'Experience Level', label: "Number of Properties Previously Invested In", name: "entry.76145354", isMasked: false, },
        { label: "First-Time Investor?", name: "entry.344717348", lender: true, },
        { label: "Do You Work with a Team (Realtors, Contractors, Lenders)?", name: "entry.195284508", isMasked: false, length: true },
        { title: 'Additional Comments or Preferences', label: "Anything Else You’d Like to Share About Your Investment Goals?", name: "entry.1758501638", isMasked: false, length: true },
    ];

    const addressField =
        clientType === "Seller"
            ? { label: "Listing Address", name: "entry.1067154313" }
            : clientType === "Rental"
                ? { label: "Rental Home Address", name: "entry.2052541034" }
                : null;




    const handleClientTypeChange = (e) => {
        // console.log(`Client Type Changed: ${e.target.value}`);
        setClientType(e.target.value);
    };

    const handleClick = () => {
        setOpen(true)
    }

    const handleClick1 = () => {
        setOpen(false)
    }

    const handleClick2 = () => {
        setOpen1(true);
        setOpen2(false)
    }

    const handleClick3 = () => {
        setOpen1(false);
        setOpen2(false);
    }

    const handleClick4 = () => {
        setOpen1(false);
        setOpen2(true)
    }

    const handleChange = (event, newValue, fieldName) => {
        // Handling regular inputs
        const { name, value, type, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
        if (name === "entry.5241176" && "entry.897752100") {
            const phoneRegex = formData.countryCode === "+1" ? /^\d{10}$/ : /^\d{10}$/;
            if (!phoneRegex.test(value)) {
                setErrors((prev) => ({ ...prev, [name]: "Invalid phone number, Enter Valid Number" }));
            } else {
                setErrors((prev) => ({ ...prev, [name]: "" }));
            }
        }
    };

    const handleDateChange = (newValue) => {
        if (newValue) {
            const formattedDate = format(newValue, "MM-dd-yyyy"); // Ensures only date, no timestamp
            // console.log("Selected Date:", formattedDate); // Debugging Log
            setSelectedDate(newValue);
            setFormData({
                ...formData,
                "entry.777777": formattedDate, // Store only the formatted date
            });
        }
    };


    const countryCodes = [
        { code: "+1", label: "🇺🇸 +1 (US)", mask: "(000) 000-0000" },
        { code: "+91", label: "🇮🇳 +91 (India)", mask: "00000-00000" },
    ];
    const handleMaskedChange = (value, name) => {
        console.log(`Masked Input Value: ${value}`);
        setFormData({ ...formData, [name]: value });

        const phoneRegex = formData.countryCode === "+1" ? /^\d{10}$/ : /^\d{10}$/;
        if (!phoneRegex.test(value.replace(/\D/g, ""))) {
            setErrors((prev) => ({ ...prev, [name]: "Invalid phone number. Must be 10 digits matching the selected country code." }));
        } else {
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
    };

    const fetchAddressSuggestions = async (inputValue) => {
        if (inputValue.length > 2) {
            try {
                const response = await fetch(
                    `https://us-central1-dgupta-homes.cloudfunctions.net/api/places?input=${inputValue}`
                );
                const data = await response.json();
                setAddressOptions(
                    data.predictions.map((place) => ({
                        label: place.description,
                        structured_formatting: place.structured_formatting, // Contains main_text & secondary_text
                    }))
                );
            } catch (error) {
                console.error("Error fetching address suggestions:", error);
            }
        }
    };

    const handleAddressChange = (event, newValue, field) => {
        fetchAddressSuggestions(newValue);
        setAddress((prev) => ({ ...prev, [field]: newValue }));
    };

    // Handle selecting an address from dropdown
    const handleAddressSelect = (event, selectedValue, field) => {
        if (selectedValue) {
            const addressText = selectedValue?.description || selectedValue;
            setAddress((prev) => ({ ...prev, [field]: addressText ? addressText.label : "" }));
        }
    };


    // const handleAddressChange = async (event, newValue) => {
    //     setAddress({
    //         ...address,
    //         currentAddress: event.target.value,
    //         listingAddress: event.target.value // Sync both fields
    //     });
    //     if (newValue.length > 2) {
    //         try {
    //             const response = await fetch(
    //                 `http://localhost:7733/places?input=${newValue}`
    //             );
    //             const data = await response.json();
    //             setAddressOptions(
    //                 data.predictions.map((place) => ({
    //                     label: place.description,
    //                     structured_formatting: place.structured_formatting, // Contains main_text & secondary_text
    //                 }))
    //             );
    //         } catch (error) {
    //             console.error("Error fetching address suggestions:", error);
    //         }
    //     }
    // };
    // const handleSubmit = async (e) => {
    //     e.preventDefault(); // Prevent default form submission

    //     const formUrl = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSedRhZakRCpJk1gB8Bc3-ze-3Ey0VmPExxNPuif6MXwvLuFXQ/formResponse";

    //     const formData1 = new FormData();

    //     // Add all Google Form entry fields here
    //     formData1.append("entry.799148199", formData["entry.799148199"] || ""); // Client Type
    //     formData1.append("entry.1272924043", formData["entry.1272924043"] || ""); // Date of Birth
    //     formData1.append("entry.1337801876", formData["entry.1337801876"] || ""); // Price Range
    //     formData1.append("entry.1490396956", formData["entry.1490396956"] || ""); // City
    //     formData1.append("emailAddress", formData["emailAddress"] || ""); // Phone Number
    //     formData1.append("entry.5241176", formData["entry.5241176"] || ""); // Client Type
    //     formData1.append("entry.2108917814", formData["entry.2108917814"] || ""); // Date of Birth
    //     formData1.append("entry.1204321760", formData["entry.1204321760"] || ""); // Price Range
    //     formData1.append("entry.1084031143", formData["entry.1084031143"] || ""); // City
    //     formData1.append("entry.1455387487", formData["entry.1455387487"] || ""); // Phone Number
    //     formData1.append("entry.2002032847", formData["entry.2002032847"] || ""); // Client Type
    //     formData1.append("entry.897752100", formData["entry.897752100"] || ""); // Date of Birth
    //     formData1.append("entry.1917610119", formData["entry.1917610119"] || ""); // Price Range
    //     formData1.append("entry.2145757185", formData["entry.2145757185"] || ""); // City
    //     formData1.append("entry.752720134", formData["entry.752720134"] || ""); // Phone Number
    //     formData1.append("entry.1765483383", formData["entry.1765483383"] || ""); // Price Range
    //     formData1.append("entry.1666573593", formData["entry.1666573593"] || ""); // City
    //     formData1.append("entry.1004463610", formData["entry.1004463610"] || ""); // Phone Number
    //     formData1.append("entry.828773349", formData["entry.828773349"] || ""); // Price Range
    //     formData1.append("entry.2105385789", formData["entry.2105385789"] || ""); // City
    //     formData1.append("entry.882338762", formData["entry.882338762"] || ""); // Phone Number
    //     formData1.append("entry.1118880", formData["entry.1118880"] || ""); // Price Range
    //     formData1.append("entry.1823120465", formData["entry.1823120465"] || ""); // City
    //     formData1.append("entry.426133897", formData["entry.426133897"] || ""); // Phone Number
    //     formData1.append("entry.101550185", formData["entry.101550185"] || ""); 

    //     try {
    //       await fetch(formUrl, {
    //         method: "POST",
    //         body: formData1,
    //         mode: "no-cors", // Prevents CORS errors when submitting to Google Forms
    //       });

    //       console.log("Form submitted successfully!");
    //       setFormData({});
    //       // Show a success message (Optional)
    //       alert("Form submitted successfully!");

    //       // Redirect back after a few seconds

    //     } catch (error) {
    //       console.error("Error submitting the form:", error);
    //     }
    //   };
    React.useEffect(() => {
        if (isSubmitted) {
            setTimeout(() => {
                window.history.back(); // Navigate back to the previous page after 5 seconds
            }, 5000);
        }
    }, [isSubmitted]);

    React.useEffect(() => {
        let summary = `Client's Full Name : ${formData["entry.1272924043"] || ""} ${formData["entry.1337801876"] || ""} ${formData["entry.1490396956"] || ""} ;\n `;

        summary += `Spouse Full Name:  ${formData["entry.1204321760"] || ""} ${formData["entry.1084031143"] || ""} ${formData["entry.1455387487"] || ""} ;\n `;

        summary += `Email: ${formData["emailAddress"] || ""} ;\n `;

        if (address.currentAddress) {
            summary += `Current Address: ${address.currentAddress} ;\n `;
        }

        if (clientType === "Seller" && address.listingAddress) {
            summary += `Listing Address: ${address.listingAddress} ;\n `;
        }

        if (clientType === "Rental" && address.listingAddress) {
            summary += `Rental Home Address: ${address.listingAddress} ;\n `;
        }

        if (formData["entry.5241176"]) {
            summary += `Mobile Number: ${formData["entry.5241176"] || ""} ;`;
        }

        setClientInfo(summary);
    }, [formData, address, clientType]);


    // React.useEffect(() => {
    //     setClientInfo(
    //         `
    //         Client's Full Name : ${formData["entry.1272924043"] || ""} ${formData["entry.1337801876"] || ""} ${formData["entry.1490396956"] || ""} ;
    //         Spouse Full Name : ${formData["entry.1204321760"] || ""} ${formData["entry.1084031143"] || ""} ${formData["entry.1455387487"] || ""} ; 
    //         Email : ${formData["emailAddress"] || ""} ; 
    //         Current Address : ${address.currentAddress || ""} ;
    //         ${(clientType === "Seller") ? `Listing Address : ${address.listingAddress || ""} ;` : null} 
    //         ${(clientType === "Rental") ? `Rental Home Address : ${address.listingAddress || ""} ;` : null} 
    //         Mobile Number : ${formData["entry.5241176"] || ""}
    //          `);
    // }, [formData, address, clientType]);
    // console.log("Client Info:", clientInfo);
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("Client Info:", clientInfo); // Logs full client info
    //     console.log("address:", address.currentAddress, address.listingAddress);
    // };


    return (
        <>
            <Box sx={{ margin: 0, background: "white" }} >
                <CustomBanner>
                    <Container sx={{ marginTop: "10px", }} maxWidth="md">
                        <div className="BAnner_text_Nav">
                            <h3>Client Information </h3>
                        </div>
                    </Container>
                </CustomBanner>
                <Container component="main" maxWidth={isMatch ? 'xs' : 'md'} sx={{ mt: '2em', mb: '2em', display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <Paper
                        elevation={5}
                        sx={{
                            p: isMatch ? 2 : 4,
                            borderRadius: 2,
                            bgcolor: "#F6F5F9",
                        }}
                    >
                        <Item>
                            <Grid xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Grid item xs={6}>
                                    <img width="50px" height="50px" src={kelly} alt="HomesWaState" style={{ margin: isMatch ? 0 : '1.2em 0em' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontSize: isMatch ? '1.5em' : '2em', fontWeight: 500, fontFamily: 'auto', }}>Deepti Gupta Real Estate</Typography>
                                </Grid>
                            </Grid>
                        </Item>
                        <Divider sx={{ marginTop: '10px' }} />
                        <Grid xs={12} sx={{ marginTop: '10px' }}>
                            <Typography sx={{ color: "#555", mb: 3, fontSize: '1em', fontWeight: 400 }}> Kindly provide your contact information, as this will enable us to assist you more effectively. Please be assured that all information submitted will be treated with the utmost confidentiality. We will be in touch with you shortly.</Typography>
                        </Grid>
                        <form
                            action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSedRhZakRCpJk1gB8Bc3-ze-3Ey0VmPExxNPuif6MXwvLuFXQ/formResponse"
                            method="POST">
                            <Grid container spacing={1} sx={{ mt: '1em' }}>
                                <Grid item xs={12} sm={6} >
                                    <FormControl fullWidth required >
                                        <InputLabel id="demo-multiple-checkbox-label">Client Type</InputLabel>
                                        <Select
                                            name="entry.799148199"
                                            value={clientType}
                                            onChange={handleClientTypeChange}
                                            MenuProps={{ PaperProps: { sx: { textAlign: "center" } } }}
                                            input={<OutlinedInput label="Client Type" sx={{ bgcolor: "white" }} />}>
                                            <MenuItem sx={{ justifyContent: "center" }} value="Buyer">Buyer</MenuItem>
                                            <MenuItem sx={{ justifyContent: "center" }} value="Seller">Seller</MenuItem>
                                            <MenuItem sx={{ justifyContent: "center" }} value="Investor">Investor</MenuItem>
                                            <MenuItem sx={{ justifyContent: "center" }} value="Rental">Rental</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {/* <FormControl required >
                                            <FormLabel id="demo-controlled-radio-buttons-group">Client Type </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name='entry.1477449943'
                                            >
                                                <FormControlLabel value="Buyer" control={<Radio name='entry.1477449943' />} label="Buyer" />
                                                <FormControlLabel value="Seller" control={<Radio name='entry.1477449943' />} label="Seller" />
                                                <FormControlLabel value="Investor" control={<Radio name='entry.1477449943' />} label="Investor" />
                                            </RadioGroup>
                                        </FormControl> */}
                                </Grid>
                                {[
                                    { label: "First Name", name: "entry.1272924043", required: true },
                                    { label: "Middle Name", name: "entry.1337801876", required: false },
                                    { label: "Last Name", name: "entry.1490396956", required: true },
                                    { label: "Email", name: "emailAddress", required: true },
                                    {
                                        label: "Mobile Number",
                                        name: "entry.5241176",
                                        type: "tel",
                                        required: true,
                                        isMasked: true
                                    },
                                    // { label: "Date of Birth (DD/MM/YYYY)", name: "entry.777777", type: "text", placeholder: "DD/MM/YYYY" },
                                ].map(({ label, name, type, isMasked = false, mask = "", placeholder, required }) => (
                                    <Grid item xs={12} sm={6} key={name}>
                                        {
                                            isMasked ? (
                                                <TextField
                                                    fullWidth
                                                    label={label}
                                                    name={name}
                                                    type={type || "text"}
                                                    required
                                                    onChange={handleChange}
                                                    error={!!errors[name]}
                                                    helperText={errors[name]}
                                                    placeholder={placeholder}
                                                    sx={{ bgcolor: "white" }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <FormControl>
                                                                    <Select
                                                                        value={country}
                                                                        onChange={(e) => setCountry(e.target.value)}
                                                                        displayEmpty
                                                                        disableUnderline
                                                                        sx={{
                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                border: 'none', // Remove the default border
                                                                            },
                                                                            '& .MuiSelect-select': {
                                                                                padding: 0, // Remove padding to align with the text field
                                                                            },
                                                                            '& .MuiSelect-icon': {
                                                                                right: 0, // Adjust icon position
                                                                            },
                                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                                border: 'none', // Remove border on hover
                                                                            },
                                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                border: 'none', // Remove border on focus
                                                                            },
                                                                            '&.Mui-focused .MuiInputBase-input': {
                                                                                border: 'none', // Remove border when focused
                                                                            },
                                                                        }}
                                                                    >
                                                                        {countries.map((country) => (
                                                                            <MenuItem key={country.code} value={country.dialCode}>
                                                                                {country.dialCode}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </InputAdornment>
                                                        ),
                                                    }}

                                                />
                                            )
                                                :
                                                (
                                                    <CustomTextField fullWidth label={label} name={name} type={type || "text"} placeholder={placeholder} onChange={handleChange} required={required} InputLabelProps={type === "date" ? { shrink: true } : {}} sx={{ bgcolor: "white" }} />
                                                )
                                        }

                                    </Grid>
                                ))}
                                <Grid container item spacing={1}>
                                    {[
                                        { label: "Current Address", name: "entry.2108917814", },
                                    ].map(({ label, name, }) => (
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                freeSolo
                                                options={addressOptions}
                                                value={address.currentAddress || ""}
                                                onInputChange={(event, newValue) => handleAddressChange(event, newValue, "currentAddress")}
                                                onChange={(event, newValue) => handleAddressSelect(event, newValue, "currentAddress")}
                                                disablePortal // Ensures dropdown appears below input field
                                                renderOption={(props, option) => (
                                                    <Grid
                                                        container
                                                        component="li"
                                                        {...props}
                                                        spacing={0}
                                                        alignItems="start"
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Grid item>
                                                            <LocationOn color="primary" />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography variant="subtitle1" fontWeight="bold">
                                                                {option.structured_formatting?.main_text}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {option.structured_formatting?.secondary_text}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                renderInput={(params) => (
                                                    <CustomTextField {...params} fullWidth required label={label} name={name} onChange={handleChange} sx={{ bgcolor: "white" }} />
                                                )}
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <DatePicker
                                                    label="Date of Birth (Optional)"
                                                    name="entry.1742101766"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    renderInput={(params) => <CustomTextField {...params} name="entry.1742101766" variant='outlined' sx={{ bgcolor: "white" }} />}
                                                    slotProps={{ textField: { placeholder: "Select Date" } }} // Shows empty initially
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {
                                    addressField && (
                                        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                                            <Autocomplete
                                                freeSolo
                                                sx={{ width: '100%' }}
                                                options={addressOptions}
                                                value={address.listingAddress || ""}
                                                onInputChange={(event, newValue) => handleAddressChange(event, newValue, "listingAddress")}
                                                onChange={(event, newValue) => handleAddressSelect(event, newValue, "listingAddress")}
                                                disablePortal // Ensures dropdown appears below input field
                                                renderOption={(props, option) => (
                                                    <Grid
                                                        container
                                                        component="li"
                                                        {...props}
                                                        spacing={0}
                                                        alignItems="start"
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Grid item>
                                                            <LocationOn color="primary" />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Typography variant="subtitle1" fontWeight="bold">
                                                                {option.structured_formatting?.main_text}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {option.structured_formatting?.secondary_text}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                renderInput={(params) => (
                                                    <CustomTextField {...params} fullWidth required label={addressField.label} name={addressField.name} onChange={handleChange} sx={{ bgcolor: "white", width: '100%' }} />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                {(clientType === "Buyer") &&
                                    <Grid item xs={12} >
                                        <CustomTextField
                                            sx={{ bgcolor: "white" }}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="name"
                                            label="Lender"
                                            name='entry.1010085342'
                                            helperText="Do you possess a pre-approval/qualify letter from the lender, or are you seeking information regarding potential lenders? Please specify?" />
                                    </Grid>}
                                {/* {clientType === "Rental" && (
                                        [
                                            { label: "Rental Home Address", name: "entry.2052541034" },
                                        ].map(({ label, name }) => (
                                            <Grid item xs={12} key={name}>
                                                <CustomTextField fullWidth required label={label} name={name} onChange={handleChange} />
                                            </Grid>
                                        ))
                                    )}
                                    <Grid item xs={12} >
                                        <CustomTextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="name"
                                            label="Lender"
                                            name='entry.1010085342'
                                            helperText="Do you possess a pre-approval/qualify letter from the lender, or are you seeking information regarding potential lenders? Please specify?" />
                                    </Grid> */}
                                {/* {clientType && (
                                        <Grid item xs={6}>
                                            <CustomTextField
                                                fullWidth
                                                label="Client Information Full Name"
                                                name="clientFullName"
                                                value={`${formData["entry.111111"] || ""} ${formData["entry.222222"] || ""} ${formData["entry.333333"] || ""}`.trim()}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    )} */}
                                <Grid item xs={12} sx={{ mt: '1em', display: "flex", justifyContent: "start", alignItems: "start", }}>
                                    <FormControl required >
                                        <FormLabel id="demo-controlled-radio-buttons-group">Vesting Interest </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name='entry.1699751618'
                                        >
                                            <FormControlLabel value="Single" control={<Radio name='entry.1699751618' />} label="Single" onClick={handleClick1} />
                                            <FormControlLabel value="Married" control={<Radio name='entry.1699751618' />} label="Married" onClick={handleClick} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Container style={{ margin: '1em', display: open ? 'block' : 'none' }}>
                                    <Grid container spacing={2} sx={{ mt: 1 }}>
                                        <Grid xs={12} sm={12}>
                                            <Typography variant="h6">PLEASE NOTE - If you are married, its mandatory to fill your spouse information.</Typography>
                                            <Divider sx={{ m: '1em 0em' }} />
                                            <Typography variant="h7">As per the Washington State Legislature, property acquired after the marriage ceremony is considered community property. As such, the property belongs equally to both spouses and neither one can dispose off that property through transfer or sale without the permission of the other. </Typography>
                                        </Grid>
                                        {[
                                            { label: "Spouse's First Name", name: "entry.1204321760", required: true },
                                            { label: "Spouse's Middle Name", name: "entry.1084031143", required: false },
                                            { label: "Spouse's Last Name", name: "entry.1455387487", required: true },
                                            { label: "Spouse's Email", name: "entry.2002032847", required: true },
                                            {
                                                label: "Spouse's Mobile Number",
                                                name: "entry.897752100",
                                                type: "tel",
                                                isMasked: true
                                            },
                                        ].map(({ label, name, type, isMasked = false, mask = "", required, placeholder }) => (
                                            <Grid item xs={12} sm={6} key={name}>
                                                {
                                                    isMasked ? (
                                                        <TextField
                                                            fullWidth
                                                            label={label}
                                                            name={name}
                                                            type={type || "text"}
                                                            required={open && required}
                                                            disabled={!open}
                                                            onChange={handleChange}
                                                            error={!!errors[name]}
                                                            helperText={errors[name]}
                                                            placeholder={placeholder}
                                                            sx={{ bgcolor: "white" }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <FormControl>
                                                                            <Select
                                                                                value={country}
                                                                                onChange={(e) => setCountry(e.target.value)}
                                                                                displayEmpty
                                                                                disableUnderline
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none', // Remove the default border
                                                                                    },
                                                                                    '& .MuiSelect-select': {
                                                                                        padding: 0, // Remove padding to align with the text field
                                                                                    },
                                                                                    '& .MuiSelect-icon': {
                                                                                        right: 0, // Adjust icon position
                                                                                    },
                                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none', // Remove border on hover
                                                                                    },
                                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                        border: 'none', // Remove border on focus
                                                                                    },
                                                                                    '&.Mui-focused .MuiInputBase-input': {
                                                                                        border: 'none', // Remove border when focused
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {countries.map((country) => (
                                                                                    <MenuItem key={country.code} value={country.dialCode}>
                                                                                        {country.dialCode}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </InputAdornment>
                                                                ),
                                                                // inputComponent: IMaskInput,
                                                                // inputProps: {
                                                                //     mask: countryCodes.find((c) => c.code === formData.countryCode)?.mask || "(000) 000-0000",
                                                                //     onAccept: handleMaskedChange,
                                                                // },
                                                            }}

                                                        />
                                                    )
                                                        :
                                                        (
                                                            <CustomTextField fullWidth label={label} name={name} type={type || "text"} placeholder={placeholder} onChange={handleChange} required={open && required} disabled={!open} sx={{ bgcolor: "white" }} />
                                                        )
                                                }
                                            </Grid>
                                        ))}
                                        <Grid xs={12} sm={12}>
                                            <Divider sx={{ mt: '1.5em' }} />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", alignItems: "start", }}>
                                    <FormControl required >
                                        <FormLabel id="demo-controlled-radio-buttons-group">How did you Hear About us? </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name='entry.821702468'
                                        >
                                            <FormControlLabel value="Recommended by friend or colleague" control={<Radio name='entry.821702468' />} label=" Recommended by friend or colleague" onClick={handleClick2} />
                                            {
                                                open1 ?
                                                    <Grid xs={isMatch ? 12 : 8} >
                                                        <CustomTextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="name"
                                                            label="Friend or colleague Full Name"
                                                            name='entry.1765483383'
                                                            autoComplete="name"
                                                            className="text_contact"
                                                            sx={{ width: isMatch ? '100%' : '130%', ml: '2em', mt: '0em', bgcolor: '#fff' }}
                                                        />
                                                    </Grid>
                                                    :
                                                    null
                                            }
                                            <FormControlLabel value="Social media (FB, WhatsApp, Insta)" control={<Radio name='entry.821702468' />} label="Social media (FB, WhatsApp, Insta)" onClick={handleClick3} />
                                            <FormControlLabel value="Search engine (Google, Zillow, etc)" control={<Radio name='entry.821702468' />} label="Search engine (Google, Zillow, etc)" onClick={handleClick3} />
                                            <FormControlLabel value="Other" control={<Radio name='entry.821702468' />} label="Other" onClick={handleClick4} />
                                            {
                                                open2 ?
                                                    <Grid xs={isMatch ? 12 : 8} >
                                                        <CustomTextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="name"
                                                            label="Other"
                                                            name='entry.80826698'
                                                            autoComplete="name"
                                                            className="text_contact"
                                                            sx={{ width: isMatch ? '100%' : '130%', ml: '2em', mt: '0em', bgcolor: '#fff' }}
                                                        />
                                                    </Grid>
                                                    :
                                                    null
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {(clientType === "Investor") &&
                                <Grid container spacing={1} sx={{ background: 'lightgrey', m: '10px -2px', p: '1em', borderRadius: '5px' }} >
                                    <Grid container spacing={2}>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={index}>
                                                {field.title && (
                                                    <Grid item xs={12}>
                                                        <Divider textAlign="left" sx={{ mt: 1 }}>
                                                            <Typography sx={{ color: "#555", fontSize: '1.1em', fontWeight: 500 }}>
                                                                {field.title}
                                                            </Typography>
                                                        </Divider>

                                                        {/* <Divider /> */}
                                                    </Grid>
                                                )}

                                                <Grid item xs={12} sm={field.length ? 12 : 6}>
                                                    {field.isMasked ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>{field.label}</InputLabel>
                                                            <Select
                                                                name={field.name}
                                                                value={formData[field.name] || []} onChange={handleChange}
                                                                input={<OutlinedInput label={field.label}
                                                                    sx={{ bgcolor: '#fff' }} />}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {field.options.map((option) => (
                                                                    <MenuItem key={option.value} value={option.label}>{option.label}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    ) : field.lender ? (
                                                        <FormControl component="fieldset" >
                                                            <FormLabel>{field.label}</FormLabel>
                                                            <RadioGroup name={field.name} row>
                                                                <FormControlLabel value="Yes" control={<Radio name={field.name} />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio name={field.name} />} label="No" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    ) : field.isAutoComplete ? (
                                                        <Autocomplete
                                                            freeSolo
                                                            options={addressOptions}
                                                            value={address.investmentLocation || ""}
                                                            onInputChange={(event, newValue) => handleAddressChange(event, newValue, "investmentLocation")}
                                                            onChange={(event, newValue) => handleAddressSelect(event, newValue, "investmentLocation")}
                                                            disablePortal // Ensures dropdown appears below input field
                                                            renderOption={(props, option) => (
                                                                <Grid
                                                                    container
                                                                    component="li"
                                                                    {...props}
                                                                    spacing={0}
                                                                    alignItems="start"
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Grid item>
                                                                        <LocationOn color="primary" />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography variant="subtitle1" fontWeight="bold">
                                                                            {option.structured_formatting?.main_text}
                                                                        </Typography>
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            {option.structured_formatting?.secondary_text}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            renderInput={(params) => (
                                                                <CustomTextField {...params} fullWidth required label={field.label} name={field.name} onChange={handleChange} sx={{ bgcolor: "white" }} />
                                                            )}
                                                        />
                                                    ) : field.length ? (
                                                        <CustomTextField fullWidth label={field.label} name={field.name} variant="outlined" multiline rows={3} />
                                                    ) : (
                                                        <CustomTextField fullWidth label={field.label} name={field.name} variant="outlined" />
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                    {/* {(clientType === "Investor") && (
                                        [
                                            { title: 'Investment Goals', label: "Type of Investment", name: "entry.1666573593", isMasked: true, options: investmentTypes },
                                            { label: "Expected ROI", name: "entry.1004463610", isMasked: false, },
                                            { label: "Preferred Holding Period", name: "entry.1004463610", isMasked: true, options: holdingPeriods },
                                            { label: "Interest in Off-Market Deals", name: "entry.1004463610", lender: true, },
                                            { title: 'Financial Information', label: "Budget Range for Investment", name: "budgetRange", isMasked: false },
                                            { label: "Preferred Financing Method", name: "entry.1004463610", isMasked: true, options: financingMethods },
                                            { label: "Proof of Funds Available?", name: "entry.1004463610", lender: true, },
                                            { label: "Open to Partnering with Other Investors?", name: "entry.1004463610", lender: true, },
                                        ].map(({ title, label, name, isMasked = false, lender = false, options }) => (
                                            <Grid item xs={12} sm={6} key={name}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography sx={{ color: "#555", fontSize: '1.1em', fontWeight: 500 }}>{title}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={lender ? 12 : 6} >
                                                    {
                                                        isMasked ?
                                                            <FormControl fullWidth>
                                                                <InputLabel>{label}</InputLabel>
                                                                <Select name={name} value={formData[name] || []} onChange={handleChange} input={<OutlinedInput label={label} sx={{ bgcolor: '#fff' }} />}>
                                                                    {options.map((option) => (
                                                                        <MenuItem key={option.value} value={option.label}>{option.label}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            :
                                                            <>
                                                                {
                                                                    (isMasked === false) && lender ?
                                                                        <FormControl component="fieldset" sx={{ p: 1 }}>
                                                                            <FormLabel>{label}</FormLabel>
                                                                            <RadioGroup name={name} row>
                                                                                <FormControlLabel value="Yes" control={<Radio name={name} />} label="Yes" />
                                                                                <FormControlLabel value="No" control={<Radio name={name} />} label="No" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                        :
                                                                        <CustomTextField fullWidth label={label} name={name} onChange={handleChange} sx={{ bgcolor: "white" }} />
                                                                }
                                                            </>

                                                    }
                                                </Grid>
                                            </Grid>
                                        ))
                                    )} */}
                                </Grid>
                            }
                            {(clientType === "Buyer") && <Grid container spacing={1} sx={{ background: 'lightgrey', m: '10px -2px', p: '1em 1em 1em 0.5em', borderRadius: '5px' }} >
                                <Grid xs={12} sx={{ mt: '5px', mb: '5px' }}>
                                    <Typography sx={{ color: "#555", fontSize: '1.1em', fontWeight: 500 }}>
                                        We’d love to hear more about your specific criteria, like your preferred price range, square footage, acreage, number of bedrooms, and any areas you have in mind.
                                    </Typography>
                                </Grid>
                                {(clientType === "Buyer") && (
                                    [
                                        { label: "Starting Price", name: "entry.1666573593", options: Price },
                                        { label: "Ending Price", name: "entry.1004463610", options: Price },
                                        { label: "Min Sqft", name: "entry.828773349", options: minSqftOptions, },
                                        { label: "Max Sqft", name: "entry.2105385789", options: maxSqftOptions },
                                    ].map(({ label, name, options }) => (
                                        <Grid item xs={12} sm={6} key={name}>
                                            <FormControl fullWidth>
                                                <InputLabel>{label}</InputLabel>
                                                <Select name={name} value={formData[name] || []} onChange={handleChange} input={<OutlinedInput label={label} sx={{ bgcolor: '#fff' }} />} MenuProps={MenuProps}>
                                                    {options.map((option) => (
                                                        <MenuItem key={option.value} value={option.label}>{option.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    ))
                                )}
                                {(clientType === "Buyer") && (
                                    [
                                        { label: "Min Acres", name: "entry.882338762", options: minacresOptions },
                                        { label: "Max Acres", name: "entry.1118880", options: maxAcresOptions },
                                        { label: "Beds", name: "entry.1823120465", options: bedOptions, dropDown: true },
                                        { label: "Baths", name: "entry.426133897", options: bathOptions, dropDown: true },
                                        { label: "Preferred Facing", name: "entry.1043794531", options: direct },
                                        { label: "Preferred Areas", name: "entry.101550185", options: cities },
                                    ].map(({ label, name, options, dropDown = false }) => (
                                        <>
                                            <Grid item xs={12} sm={6} key={name}>
                                                {
                                                    dropDown ?
                                                        <CustomTextField fullWidth label={label} name={name} onChange={handleChange} sx={{ bgcolor: "white" }} />
                                                        :
                                                        <FormControl fullWidth>
                                                            <InputLabel>{label}</InputLabel>
                                                            <Select name={name} multiple onChange={handleChange}
                                                                value={formData[name] || []}
                                                                renderValue={(selected) => selected.join(', ')}
                                                                input={<OutlinedInput label={label} sx={{ bgcolor: '#fff' }} />}
                                                                MenuProps={MenuProps}>
                                                                {options.map((option) => (
                                                                    <MenuItem key={option.value} value={option.label}>
                                                                        <Checkbox checked={(formData[name] || []).indexOf(option.label) > -1} />
                                                                        <ListItemText primary={option.label} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                }
                                            </Grid>
                                        </>
                                    ))
                                )}
                            </Grid>
                            }
                            <Grid item xs={12} sm={6} sx={{ mt: '1em' }}>
                                {
                                    clientType === "Investor" ?
                                        null
                                        :
                                        <CustomTextField fullWidth label={clientType === "Rental" ? "Excepted Rental Price and Date" : "Other Requirements"} name="entry.1452267217" variant="outlined" multiline rows={3} />
                                }
                            </Grid>
                            <input type="hidden" name="entry.1917610119" // Full Name
                                value={`${formData["entry.1272924043"] || ""} ${formData["entry.1337801876"] || ""} ${formData["entry.1490396956"] || ""}`}
                            // onChange={handleChange}
                            />
                            <input type="hidden" name="entry.2145757185" // Client Info
                                value={clientInfo}
                            // onChange={handleChange}
                            />
                            <input type="hidden" name="entry.752720134" // Spouse Full Name
                                value={`${formData["entry.1204321760"] || ""} ${formData["entry.1084031143"] || ""} ${formData["entry.1455387487"] || ""}`}
                            // onChange={handleChange}
                            />
                            <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                                <Button
                                    sx={{ width: '70%', mt: '1em', bgcolor: "#3f3326", ":hover": { bgcolor: "#5c4d39" } }}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={!!errors["entry.5241176"] || !!errors["entry.897752100"] || !!errors["emailAddress"]}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </form>
                    </Paper>
                </Container>

            </Box>
            <div>
                <Footer />
            </div>
        </>
    )
}


const cities = [
    { value: 'Bothell', label: 'Bothell' },
    { value: 'Sammamish', label: 'Sammamish' },
    { value: 'Kirkland', label: 'Kirkland' },
    { value: 'Lynnwood', label: 'Lynnwood' },
    { value: 'Lake Stevens', label: 'Lake Stevens' },
    { value: 'Maple Valley', label: 'Maple Valley' },
    { value: 'Redmond', label: 'Redmond' },
    { value: 'Bellevue', label: 'Bellevue' },
    { value: 'Issaquah', label: 'Issaquah' },
    { value: 'Mill Creek', label: 'Mill Creek' },
    { value: 'Renton', label: 'Renton' },
    { value: 'Puyallup', label: 'Puyallup' },
]


const Price = [
    { value: '100000', label: '$ 100,000' },
    { value: '200000', label: '$ 200,000' },
    { value: '300000', label: '$ 300,000' },
    { value: '400000', label: '$ 400,000' },
    { value: '500000', label: '$ 500,000' },
    { value: '600000', label: '$ 600,000' },
    { value: '700000', label: '$ 700,000' },
    { value: '700000', label: '$ 800,000' },
    { value: '900000', label: '$ 900,000' },
    { value: '1000000', label: '$ 1,000,000' },
    { value: '1100000', label: '$ 1,100,000' },
    { value: '1200000', label: '$ 1,200,000' },
    { value: '13000000', label: '$ 1,300,000' },
    { value: '1400000', label: '$ 1,400,000' },
    { value: '1500000', label: '$ 1,500,000' },
    { value: '1600000', label: '$ 1,600,000' },
    { value: '1700000', label: '$ 1,700,000' },
    { value: '1800000', label: '$ 1,800,000' },
    { value: '1900000', label: '$ 1,900,000' },
    { value: '2000000', label: '$ 2,000,000' },
    { value: '2100000', label: '$ 2,100,000' },
    { value: '2200000', label: '$ 2,200,000' },
    { value: '2300000', label: '$ 2,300,000' },
    { value: '2400000', label: '$ 2,400,000' },
    { value: '2500000', label: '$ 2,500,000' },
    { value: '2600000', label: '$ 2,600,000' },
    { value: '2700000', label: '$ 2,700,000' },
    { value: '2800000', label: '$ 2,800,000' },
    { value: '20000000', label: '$ 2,900,000' },
    { value: '3000000', label: '$ 3,000,000' },
    { value: '3100000', label: '$ 3,100,000' },
    { value: '3200000', label: '$ 3,200,000' },
    { value: '3300000', label: '$ 3,300,000' },
    { value: '3400000', label: '$ 3,400,000' },
    { value: '3500000', label: '$ 3,500,000' },
    { value: '3600000', label: '$ 3,600,000' },
    { value: '3700000', label: '$ 3,700,000' },
    { value: '3800000', label: '$ 3,800,000' },
    { value: '3900000', label: '$ 3,900,000' },
    { value: '4000000', label: '$ 4,000,000' },
    { value: '4100000', label: '$ 4,100,000' },
    { value: '4200000', label: '$ 4,200,000' },
    { value: '4300000', label: '$ 4,300,000' },
    { value: '4400000', label: '$ 4,400,000' },
    { value: '4500000', label: '$ 4,500,000' },
    { value: '4600000', label: '$ 4,600,000' },
    { value: '4700000', label: '$ 4,700,000' },
    { value: '4800000', label: '$ 4,800,000' },
    { value: '4900000', label: '$ 4,900,000' },
    { value: '5000000', label: '$ 5,000,000' },
]

const minSqftOptions = [
    { value: '1000 - 1500', label: '1000 Sq.ft - 1500 Sq.ft' },
    { value: '1500 - 2000', label: '1500 Sq.ft - 2000 Sq.ft' },
    { value: '2000 - 2500', label: '2000 Sq.ft - 2500 Sq.ft' },
    { value: '2500 - 3000', label: '2500 Sq.ft - 3000 Sq.ft' },
    { value: '3000 - 3500', label: '3000 Sq.ft - 3500 Sq.ft' },
    { value: '3500 - 4000', label: '3500 Sq.ft - 4000 Sq.ft' },
    { value: '4000 - 4500', label: '4000 Sq.ft - 4500 Sq.ft' },
    { value: '4500 - 5000', label: '4500 Sq.ft - 5000 Sq.ft' },
    { value: '5000 - 5500', label: '5000 Sq.ft - 5500 Sq.ft' },
    { value: '5500 - 6000', label: '5500 Sq.ft - 6000 Sq.ft' },
];

const maxSqftOptions = [
    { value: '1000 - 1500', label: '1000 Sq.ft - 1500 Sq.ft' },
    { value: '1500 - 2000', label: '1500 Sq.ft - 2000 Sq.ft' },
    { value: '2000 - 2500', label: '2000 Sq.ft - 2500 Sq.ft' },
    { value: '2500 - 3000', label: '2500 Sq.ft - 3000 Sq.ft' },
    { value: '3000 - 3500', label: '3000 Sq.ft - 3500 Sq.ft' },
    { value: '3500 - 4000', label: '3500 Sq.ft - 4000 Sq.ft' },
    { value: '4000 - 4500', label: '4000 Sq.ft - 4500 Sq.ft' },
    { value: '4500 - 5000', label: '4500 Sq.ft - 5000 Sq.ft' },
    { value: '5000 - 5500', label: '5000 Sq.ft - 5500 Sq.ft' },
    { value: '5500 - 6000', label: '5500 Sq.ft - 6000 Sq.ft' },
];


const direct = [
    { value: 'East', label: 'East', },
    { value: 'West', label: 'West', },
    { value: 'North', label: 'North', },
    { value: 'South', label: 'South', },
]

const bedOptions = [
    { value: "1+", label: "1+" },
    { value: "2+", label: "2+" },
    { value: "3+", label: "3+" },
    { value: "4+", label: "4+" },
    { value: "5+", label: "5+" },
];

const bathOptions = [
    { value: "1+", label: "1+" },
    { value: "2+", label: "2+" },
    { value: "3+", label: "3+" },
    { value: "4+", label: "4+" },
    { value: "5+", label: "5+" },
];

const minacresOptions = [
    { value: "1", label: "1 acre" },
    { value: "5", label: "5 acres" },
    { value: "10", label: "10 acres" },
];

const maxAcresOptions = [
    { value: "5", label: "5 acres" },
    { value: "10", label: "10 acres" },
    { value: "20", label: "20 acres" },
];


const investmentTypes = [
    { value: 'Flip', label: 'Flip' },
    { value: 'Rental', label: 'Rental' },
    { value: 'Multi-Family', label: 'Multi-Family' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'New Construction', label: 'New Construction' },
    { value: 'Land Development', label: 'Land Development' },
];


const holdingPeriods = [
    { value: 'Short-term', label: 'Short-term' },
    { value: 'Medium-term', label: 'Medium-term' },
    { value: 'Long-term', label: 'Long-term' },
];

const financingMethods = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Mortgage', label: 'Mortgage' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'Hard Money', label: 'Hard Moneyash' },
];

const rentalStrategies = [
    { value: 'Short-Term Rentals', label: 'Short-Term Rentals' },
    { value: 'Long-Term Rentals', label: 'Long-Term Rentals' },
    { value: 'Lease-to-Own', label: 'Lease-to-Own' },
];

const propertyTypes = [
    { value: 'Single-Family', label: 'Single-Family' },
    { value: 'Multi-Family', label: 'Multi-Family' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Mixed-Use', label: 'Mixed-Use' },
    { value: 'Land', label: 'Land' },
];

const levelofInvolvement = [
    { value: 'Hands-On', label: 'Hands-On' },
    { value: 'Passive', label: 'Passive' },
    { value: 'Partnership', label: 'Partnership' },
];

const team = [
    { value: 'Realtors', label: 'Realtors' },
    { value: 'Contractors', label: 'Contractors' },
    { value: 'Lenders', label: 'Lenders' },
];

